export class User {
  id: number;
  name: string;
  surname: string;
  email: string;
  password: string | null;
  roles: string[];
  role: string;
  teams: any[]
}

import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { StorageService } from "src/app/services/storage.service";
import { environment } from "../../../../environments/environment";
import { User } from "../classes/user";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  readonly STORAGE_KEY = "tn";
  readonly STORAGE_ROLE = "rl";

  redirectUrl: string;

  private token: string;
  private role: boolean;

  constructor(private http: HttpClient, private storage: StorageService) {}

  getAuthorizationToken(): string | false {
    if (!this.token) {
      this.token = this.storage.get(this.STORAGE_KEY);
    }

    return this.token || false;
  }

  getAuthorizationRole(): boolean | false {
    if (!this.role) {
      this.role = Boolean(this.storage.get(this.STORAGE_ROLE));
    }

    return this.role || false;
  }

  isAuthorized(): boolean {
    return this.getAuthorizationToken() !== false;
  }

  login(username: string, password: string): Observable<User> {
    const loginData = {
      email: username,
      password: password,
    };

    return this.http
      .post<{
        admin: boolean;
        token: string; data: { user: User }
      }>(
        environment.apiUrl + "/login",
        loginData
      )
      .pipe(
        map((response) => {
          this.token = response.token;
          this.role = response.admin;
          this.storage.set(this.STORAGE_KEY, this.token);
          this.storage.set(this.STORAGE_ROLE, this.token);

          return Object.assign(new User(), response.data.user);
        }),
        catchError(this.handleError)
      );
  }

  logout() {
    this.token = null;
    this.storage.remove(this.STORAGE_KEY);
  }

  getUser(): Observable<User> {
    return this.http.get(environment.apiUrl + "/users").pipe(
      map((user) => Object.assign(new User(), user)),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMsg: string;
    if (error.error instanceof ErrorEvent) {
      errorMsg = $localize`:generic-error|:Si è verificato un errore: ${error.message}`;
    } else if (error.status == 401) {
      errorMsg = $localize`:auth-auth-error|Errore di autenticazione:Non ti sei autenticato. Nome utente e/o password errati`;
    } else if (error.status == 403) {
      errorMsg = $localize`:auth-access-forbidden|:Accesso negato`;
    } else {
      errorMsg = $localize`:generic-error-mes|:Si è verificato un errore sconosciuto.`;
    }

    return throwError(errorMsg);
  }
}
